import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from "yup";
import useSendEmail from './components/common/hooks/useSendEmail.js';
import './sass/main.scss';

const schema = yup.object().shape({
    name: yup.string().required(),
    company: yup.string().required(),
    email: yup.string().email().required(),
    description: yup.string(),
});

function ContactForm() {
    const [isSuccessfulSending, setIsSuccessfulSending] = useState(false);
    const sendEmailRequest = useSendEmail();
    const {register, handleSubmit, errors} = useForm(
        {
            resolver: yupResolver(schema)
        }
    );

    const resetSuccessfulSendingStatus = () => {
        setTimeout(() => {
            setIsSuccessfulSending(false);
        }, 3000);
    };

    const onSubmit = async (data, e) => {
        console.log(data);
        const {name, email, description, company} = data;
        await sendEmailRequest({
            type: '1',
            name,
            email,
            message: description,
            // company,
        })
            .then(res => {
                setIsSuccessfulSending(true);
                resetSuccessfulSendingStatus();
            })
            .catch(error => {});
        e.target.reset();
    };

    return (
        <form
            className="form"
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="input-wrapper">
                <input
                    name="name"
                    type="text"
                    className={['input', (errors.name ? 'error' : '')].join(' ')}
                    placeholder="Name"
                    ref={register}
                />
                {errors.name?.type === "required" && <span className='error-message'>Name is required</span>}
            </div>
            <div className="input-wrapper">
                <input
                    name="company"
                    type="text"
                    className={['input', (errors.company ? 'error' : '')].join(' ')}
                    placeholder="Company"
                    ref={register}
                />
                {errors.company?.type === "required" && <span className='error-message'>Company is required</span>}
            </div>
            <div className="input-wrapper">
                <input
                    name="email"
                    type="text"
                    className={['input', (errors.email ? 'error' : '')].join(' ')}
                    placeholder="Email"
                    ref={register}
                />
                {errors.email?.type === "required" && <span className='error-message'>Email is required</span>}
                {errors.email?.type === "email" && <span className='error-message'>Email is not correct</span>}
            </div>
            <div className="input-wrapper">
                <textarea
                    className={['text-area', (errors.description ? 'error' : '')].join(' ')}
                    name="description"
                    id="share"
                    rows="3"
                    placeholder="Got something to share?"
                    ref={register}
                />
            </div>

            {isSuccessfulSending && <span className='successful-sending'>Your message has been sent</span>}

            <div className="button-wrapper">
                <input
                    type="submit"
                    value="Send"
                    className="button send-button"
                />
                {/*<button className="button send-button">Send</button>*/}
            </div>
        </form>
    );
}

export default ContactForm;
