import React from 'react';
import ReactDOM from 'react-dom';
import 'animate.css/animate.css';
import './index.css';
import './animation'
import ContactForm from './ContactForm';
import reportWebVitals from './reportWebVitals';

function openUrlInNewTab() {
    const URL = 'http://stusan.com/'
    window.open(URL, '_blank');
}
document.getElementById('right-now-btn').addEventListener('click', openUrlInNewTab);

ReactDOM.render(
  <React.StrictMode>
    <ContactForm />
  </React.StrictMode>,
  document.getElementById('contact')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
